import React from 'react'

export default function Contact() {
  return (
    <div className="app-content">
      <form
        name="contact"
        method="post"
        action="/contact/form-success"
        className="contact-form border shadow"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label>Name:</label>
        <input
          type="text"
          name="name"
          placeholder="Your name"
          className="input-field border"
          required
        />

        <label>Email:</label>
        <input
          type="email"
          name="email"
          placeholder="Your email"
          className="input-field border"
          required
        />

        <label>Message:</label>
        <textarea
          name="message"
          placeholder="Your message"
          className="input-field border"
          required
        ></textarea>

        <button type="submit" className="submit border">
          Send Form
        </button>
      </form>
    </div>
  )
}
