import React from 'react'
import { useGetAllRequests } from './useGetAllRequests'

const url = player =>
  `https://my.callofduty.com/api/papi-client/stats/cod/v1/title/mw/platform/psn/gamer/${player}/profile/type/mp`

const gamers = [
  {
    name: 'FredrikF',
    psnUsername: 'teh_bam',
    wins: 11,
    scoreboardRank: 'gold'
  },
  {
    name: 'Anton',
    psnUsername: 'antontheofficial',
    wins: 12,
    scoreboardRank: 'gold'
  },
  {
    name: 'Gard',
    psnUsername: 'gardebassen',
    wins: 13,
    scoreboardRank: 'gold'
  },
  {
    name: 'Martin',
    psnUsername: 'martiinpoff',
    wins: 10,
    scoreboardRank: 'gold'
  },
  {
    name: 'Nils',
    psnUsername: 'matthulken',
    wins: 9,
    scoreboardRank: 'gold'
  },
  {
    name: 'Halvor',
    psnUsername: 'hvorre',
    wins: 4,
    scoreboardRank: 'gold'
  },
  {
    name: 'Ketil',
    psnUsername: 'lendeharung',
    wins: 1,
    scoreboardRank: 'gold'
  },
  {
    name: 'FredrikC',
    psnUsername: 'papachristensen',
    wins: 5,
    scoreboardRank: 'gold'
  }
]
const gamerURLs = gamers.map(gamer => (gamer.url = url(gamer.psnUsername)))
const scoreboardRank = ['gold', 'silver', 'bronze', 'notTopThree']

export default function CodAPI() {
  const gamerData = useGetAllRequests(gamerURLs)

  try {
    if (gamerData[0][0]) {
      gamers.map(
        (gamer, i) =>
          (gamer = Object.assign(
            gamer,
            gamerData[0][i].data.lifetime.mode.br.properties
          ))
      )

      /* 
      name: "Gard"
      psnUsername: "gardebassen"
      url: "https://my.callofduty.com/api/papi-client/stats/cod/v1/title/mw/platform/psn/gamer/gardebassen/profile/type/mp"
      wins: 10
      kills: 449
      kdRatio: 0.9492600422832981
      downs: 460
      topTwentyFive: 174
      topTen: 89
      contracts: 273
      revives: 0
      topFive: 55
      score: 681625
      timePlayed: 268418
      gamesPlayed: 166
      scorePerMinute: 152.36496807218592
      cash: 0
      deaths: 473
      scoreboardRank: "gold"
       */
    }
  } catch (error) {
    console.log('Error: ', gamerData[0][0])
  }

  gamers.sort((a, b) => (a.wins < b.wins ? 1 : -1))
  gamers[0].scoreboardRank = scoreboardRank[0]
  let placement = 1
  for (let i = 1; i < gamers.length; i++) {
    if (gamers[i].wins === gamers[i - 1].wins) {
      gamers[i].scoreboardRank = gamers[i - 1].scoreboardRank
    } else {
      gamers[i].scoreboardRank = scoreboardRank[placement]
      if (placement < 3) placement++
    }
  }

  return (
    <div className="app-content">
      <h2 className="leaderboard shadow">SUSH| Warzone leaderboards</h2>
      <h3>
        {!gamerData[0][0]
          ? `loading...`
          : gamerData[0][0].status === 'error'
          ? 'Error loading data from Activision API, displaying data from 13.04.20'
          : ''}
      </h3>
      {!gamerData[0][0]
        ? ''
        : gamers.map((gamer, i) => (
            <div
              className={`codHighscoreEntry ${gamer.scoreboardRank} shadow`}
              key={gamer.psnUsername}
            >
              <div className="playerWins">
                {gamer.name} wins: {gamer.wins}
              </div>
              {gamerData[0][0].status !== 'error' ? (
                <details className="playerStats">
                  <summary>Stats</summary>
                  <div>Games: {gamer.gamesPlayed}</div>
                  <div>Kills: {gamer.kills}</div>
                  <div>Deaths: {gamer.deaths}</div>
                  <div>Kills/deaths-ratio: {gamer.kdRatio.toFixed(2)}</div>
                  <div>
                    Kills/game-ratio:{' '}
                    {(gamer.kills / gamer.gamesPlayed).toFixed(2)}
                  </div>
                  <div>
                    Deaths/game-ratio:{' '}
                    {(gamer.deaths / gamer.gamesPlayed).toFixed(2)}
                  </div>
                </details>
              ) : (
                ''
              )}
            </div>
          ))}
    </div>
  )
}
