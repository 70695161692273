import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div className="app-content">
      <section className="box border shadow">
        <h2>Hi.</h2>
        <p>I'm a freelance frontend web developer based in Oslo, Norway.</p>
        <p>
          Most of my work is done in <span className="buzzword">HTML</span>,{' '}
          <span className="buzzword">CSS</span>,{' '}
          <span className="buzzword">JavaScript</span>, and{' '}
          <span className="buzzword">React</span>. I've also dabbled with the{' '}
          <span className="buzzword">Google Sheets API</span>, and various other
          APIs.
        </p>
      </section>
      <section className="box border shadow">
        <p>
          Have a look at some projects in my{' '}
          <Link to="/portfolio">portfolio</Link>, or at my{' '}
          <a
            href="https://github.com/fredrikif"
            rel="noopener noreferrer"
            target="_blank"
          >
            github
          </a>
        </p>
      </section>
    </div>
  )
}
