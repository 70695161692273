import React from 'react'
import { Link } from 'react-router-dom'

export default function Portfolio() {
  return (
    <div className="app-content">
      <section className="border box shadow">
        <h2>Handlelappen (Grocery list webapp)</h2>
        <p>
          A webapp to fill a need for a simple grocery list. Synchs
          automatically by subscribing to snapshot of firestore.
        </p>
        <p>
          <span className="buzzword">Firebase</span>,{' '}
          <span className="buzzword">Firestore</span>,{' '}
          <span className="buzzword">React</span>,{' '}
          <span className="buzzword">CSS</span>{' '}
        </p>
        <p>
          <a
            href="https://handleliste.netlify.app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Handlelappen
          </a>
        </p>
      </section>
      <section className="border box shadow">
        <h2>COD Warzone Leaderboard</h2>
        <p>
          Deprecated project from when COD's API was public. Currently displays
          old data.
        </p>
        <p>
          <span className="buzzword">Fetch API</span>,{' '}
          <span className="buzzword">JavaScript</span>,{' '}
          <span className="buzzword">React</span>,{' '}
          <span className="buzzword">CSS</span>{' '}
        </p>
        <p>
          <Link to="/portfolio/cod">Warzone Leaderboard</Link>
        </p>
      </section>
      <section className="border box shadow">
        <h2>Sneipfritt</h2>
        <p>Data analysis for Sneipfritt.</p>
        <p>
          <span className="buzzword">Chart.js</span>,{' '}
          <span className="buzzword">Google Sheets API</span>,{' '}
          <span className="buzzword">JavaScript</span>,{' '}
          <span className="buzzword">HTML</span>,{' '}
          <span className="buzzword">CSS</span>{' '}
        </p>
        <p>sneipfritt url eventually</p>
      </section>
      <section className="border box shadow">
        <h2>ASVL Kunnskapsbase</h2>
        <p>
          Knowledge base for ASVL, regarding various measures of their business.
          Used for quick access during development, later to be implemented on
          their website{' '}
          <a href="https://asvl.no/digitale-verktoy/" rel="noopener noreferrer">
            ASVL.no
          </a>
        </p>
        <p>
          <span className="buzzword">React</span>,{' '}
          <span className="buzzword">JavaScript</span>,{' '}
          <span className="buzzword">CSS</span>,{' '}
          <span className="buzzword">Research</span>{' '}
        </p>
        <p>
          <Link to="/portfolio/asvl">Kunnskapsbase</Link>
        </p>
      </section>
    </div>
  )
}
