import React from 'react'
import { db } from './asvlDB'
import './asvlStyle.css'

export default function Asvl() {
  const renderNav = (db) => (
    <nav id="toTheTop" className="box shadow border">
      <h1 className="asvlHeadings">Navigasjon: </h1>
      {db.map((data) => (
        <a className="asvlNavLink" key={data.name} href={`#${data.name}`}>
          {data.name}
        </a>
      ))}
    </nav>
  )

  const renderStudyInfo = (data, studyInfo) =>
    data[studyInfo] !== undefined ? (
      <div>
        <h2 className="asvlHeadings">{studyInfo}</h2>
        {data[studyInfo].map((category) => (
          <section key={category.name} className={`box shadow ${studyInfo}`}>
            <h3 className="asvlHeadings">{category.name}</h3>
            <p className="">
              <b>Kilde: </b>
              <a href={category.url} target="_blank" rel="noopener noreferrer">
                {category.name}
              </a>
            </p>
            <p>
              <b>Oppsummering: </b>
              {category.description.join('')}
            </p>
          </section>
        ))}
      </div>
    ) : (
      ''
    )

  return (
    <div className="asvl">
      {renderNav(db)}
      {db.map((data) => (
        <details key={data.name} className="box border shadow">
          <summary id={data.name}>{data.name}</summary>
          <a className="" href="#toTheTop">
            Toppen av siden
          </a>
          {renderStudyInfo(data, 'informasjon')}
          {renderStudyInfo(data, 'studier')}
          {renderStudyInfo(data, 'metoder')}
        </details>
      ))}
    </div>
  )
}
