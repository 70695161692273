import { useState, useEffect, useMemo } from 'react'

export const useGetAllRequests = URLs => {
  const [fetchedData, setFetchedData] = useState([])

  const fetchArr = useMemo(
    () =>
      URLs.map(function(url) {
        return fetch(url)
      }),
    [URLs]
  )

  useEffect(() => {
    Promise.all(fetchArr)
      .then(function(response) {
        const responses = response.map(res => res.json())
        return Promise.all(responses)
      })
      .then(function(data) {
        setFetchedData(data)
      })
  }, [fetchArr])

  return [fetchedData]
}
