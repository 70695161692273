import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import Navbar from './components/Navbar'
import Home from './components/Home'
import Contact from './components/Contact'
import Portfolio from './components/Portfolio'
import Wrapper from './components/Wrapper'
import ContactSuccess from './components/ContactSuccess'
import CodAPI from './components/cod/CodAPI'
import Asvl from './components/asvl/Asvl'

function App() {
  return (
    <Wrapper>
      <Router>
        <Header />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/portfolio/cod" component={CodAPI} />
          <Route exact path="/portfolio/asvl" component={Asvl} />
          <Route exact path="/contact" component={Contact} />
          <Route
            exact
            path="/contact/form-success"
            component={ContactSuccess}
          />
        </Switch>
      </Router>
    </Wrapper>
  )
}

export default App
