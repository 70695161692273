import React from 'react'

export default function ContactSuccess() {
  return (
    <div className="app-content">
      <section className="box">
        <h1>Success!</h1>
        <p>
          Your form has been successfully submitted, and I will get back to you
          soon!
        </p>
      </section>
    </div>
  )
}
