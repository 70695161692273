import React, { useState, useEffect } from 'react'

/*

  Wrapper component that deals with resizing viewport. 
  Fixes vh issue on mobile by providing an accurate height.

*/

export default function Wrapper(props) {
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <div className="app-container" style={{ height: height }}>
      {props.children}
    </div>
  )
}
