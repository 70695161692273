import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Navbar() {
  return (
    <nav className="app-nav">
      <NavLink
        to="/"
        exact
        activeClassName="navbarLink--active"
        className="navbarLink"
      >
        Home
      </NavLink>
      <NavLink
        to="/portfolio"
        exact
        className="navbarLink"
        activeClassName="navbarLink--active"
      >
        Portfolio
      </NavLink>
      <NavLink
        to="/contact"
        exact
        className="navbarLink"
        activeClassName="navbarLink--active"
      >
        Contact
      </NavLink>
    </nav>
  )
}
